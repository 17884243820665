import React, { useEffect, useState, useRef } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '@fontsource/playfair-display';
import '@fontsource/roboto';
import './header.css';
import classNames from 'classnames';

export const Header = ({ data = { title: 'Welcome', paragraph: '' } }) => {
  const [currentItem, setCurrentItem] = useState(0);
  const [isExiting, setIsExiting] = useState(false);
  const [typedText, setTypedText] = useState('');
  const [progress, setProgress] = useState(0);

  const slides = [
    { type: 'image', class: 'bg-image-1' },
    { type: 'video', class: 'bg-video', src: '/vid/intro.mp4' },
    { type: 'image', class: 'bg-image-2' },
    { type: 'image', class: 'bg-image-3' },
  ];

  const slideIntervalRef = useRef(null);
  const progressIntervalRef = useRef(null);

  // Slideshow logic
  useEffect(() => {
    const startSlideshow = () => {
      slideIntervalRef.current = setInterval(() => {
        setIsExiting(true);
        setProgress(0);

        setTimeout(() => {
          setCurrentItem((prev) => (prev + 1) % slides.length);
          setIsExiting(false);
        }, 1000);
      }, 5000);

      progressIntervalRef.current = setInterval(() => {
        setProgress((prev) => (prev >= 100 ? 0 : prev + (50 / 5000) * 100));
      }, 50);
    };

    startSlideshow();

    return () => {
      clearInterval(slideIntervalRef.current);
      clearInterval(progressIntervalRef.current);
    };
  }, [slides.length]);

  // AOS initialization
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);

  // Typing effect for the paragraph
  useEffect(() => {
    let isTyping = true;
    let charIndex = 0;
    const typeText = () => {
      if (isTyping && charIndex < data.paragraph.length) {
        setTypedText((prev) => prev + data.paragraph.charAt(charIndex));
        charIndex++;
        setTimeout(typeText, 100);
      }
    };

    typeText();

    return () => {
      isTyping = false; // Stop typing if the component unmounts
    };
  }, [data.paragraph]);

  const renderBackground = () => {
    const currentSlide = slides[currentItem];

    if (currentSlide.type === 'video') {
      return (
        <video
          className="background-video"
          autoPlay
          muted
          loop
          playsInline
          aria-label="Background Video"
        >
          <source src={currentSlide.src} type="video/mp4" />
        </video>
      );
    }
    return null;
  };

  const headerClass = classNames('intro', slides[currentItem].class);
  const overlayClass = classNames('overlay', {
    slide: true,
    exit: isExiting,
    active: !isExiting,
  });

  return (
    <header id="header" className={headerClass}>
      {renderBackground()}
      <div className="progress-line">
        <div
          className="progress-line-fill"
          style={{ width: `${progress}%` }}
        />
      </div>
      <div className={overlayClass}>
        <div className="container" data-aos="fade-up">
          <div className="intro-text" data-aos="zoom-in">
            <h1 style={{ fontFamily: 'Playfair Display, serif' }}>
              {data.title}
              <span></span>
            </h1>
            <p className="typing">{typedText}</p>
            <a
              href="#features"
              className="btn btn-custom btn-lg page-scroll"
              data-aos="fade-right"
            >
              Learn More
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};
