// AboutCards.js
import React from 'react';
import 'aos/dist/aos.css';
import "./aboutcards.css";

const AboutCards = () => {
  const aboutItems = [
    {
      icon: "fa-book",
      title: "MISSION",
      description: "Our mission is to deliver innovative and reliable ICT solutions that empower businesses and organizations in Uganda to achieve their goals and thrive in the digital age.",
      className: "mission"
    },
    {
      icon: "fa-globe",
      title: "VISION",
      description: "Our vision is to be a leading provider of comprehensive ICT solutions in Uganda, recognized for our commitment to excellence, innovation, and customer satisfaction.",
      className: "vision"
    },
    {
      icon: "fa-laptop",
      title: "OUR WORK",
      description: "We offer a wide range of ICT services to meet the diverse needs of our clients. These services include: Infrastructure and Network Services, Software Development, IT Consulting and Support, Data Management and Analytics, Digital Transformation Services.",
      className: "work"
    }
  ];

  return (
    <div className="about-background">
      <div className="about-section">
        <div className="gradient-cards">
          {aboutItems.map((item, index) => (
            <div key={item.title} className={`about-item ${item.className}`} data-aos="fade-up" data-aos-delay={index * 100}>
              <i className={`fa ${item.icon}`}></i>
              <h3>{item.title}</h3>
              <hr />
              <p>{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutCards;
