import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './navigation.css';

const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;
  return function () {
    const context = this;
    const args = arguments;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(function () {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};

export const Navigation = React.memo(() => {
  const [isOpen, setIsOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [visible, setVisible] = useState(true);

  const handleToggle = () => setIsOpen(!isOpen);
  const handleLinkClick = () => setIsOpen(false);

  useEffect(() => {
    const handleScroll = throttle(() => {
      const currentScrollPos = window.scrollY;
      setVisible(prevScrollPos > currentScrollPos);
      setPrevScrollPos(currentScrollPos);
    }, 200);

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  return (
    <nav
      id="menu"
      className={`navbar navbar-default navbar-fixed-top ${visible ? 'navbar-visible' : 'navbar-hidden'}`}
    >
      <div className="container">
        <div className="navbar-header">
          <Link className="navbar-brand page-scroll" to="/">
            <img
              src="/img/logo/logo.png"
              alt="Terry Technologies Logo"
              className="navbar-logo"
            />
          </Link>
          <button type="button" className="navbar-toggle collapsed" onClick={handleToggle}>
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
        </div>
        <div className={`collapse navbar-collapse ${isOpen ? 'in' : ''}`} id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
            <li>
              <NavLink to="/"  activeClassName="active" onClick={handleLinkClick}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/services" activeClassName="active" onClick={handleLinkClick}>
                Services
              </NavLink>
            </li>
            <li>
              <NavLink to="/whoweare"  onClick={handleLinkClick}>
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink to="/careers"  activeClassName="active" onClick={handleLinkClick}>
                Careers
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact"  activeClassName="active" onClick={handleLinkClick}>
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
});

export default Navigation;
