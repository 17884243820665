import React, { useEffect } from 'react';
import AOS from 'aos';
import { Contact } from '../contact/contact';
import AboutCards from './AboutCards';
import Team from './Team';
import 'aos/dist/aos.css';
import './whoweare.css';

export const WhoWeAre = ({ data }) => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <section className="space-background">
      {/* Space Background Elements */}
      <div className="stars"></div>
      <div className="grid-overlay"></div>
      
      {/* Animated Gradient Lines */}
      <div className="gradient-lines">
        {[...Array(5)].map((_, index) => (
          <div key={index} className={`line line-${index + 1}`}></div>
        ))}
      </div>

      {/* Content */}
      <div className="content-wrapper">
        {/* Hero Section */}
        <div className="hero" data-aos="fade-up">
          <h1>ABOUT TERRY TECHNOLOGIES LIMITED</h1>
          <p>
            Terry Technologies Limited is a leading provider of Information and
            Communication Technology (ICT) solutions in Uganda.
          </p>
        </div>

        {/* About Cards Section */}
        <div data-aos="fade-up" data-aos-delay="100" className="about-cards">
          <AboutCards />
        </div>

        {/* Team Section */}
        <div data-aos="fade-up" data-aos-delay="200" className="team-section">
          <Team data={data} />
        </div>

        {/* Contact Section */}
        <div data-aos="fade-up" data-aos-delay="300" className="contact-section">
          <Contact />
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
