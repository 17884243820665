import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import './home1.css';

export const Home1 = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Whether animation should happen only once
    });
  }, []);

  return (
    <div id="home1" className="home1-section" data-aos="fade-up">
      <video autoPlay muted loop className="background-video">
        <source src="/vid/2.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xs-12 col-md-8">
            <div className="home1-text text-center" data-aos="fade-up">
              <h2>Digital Transformation of Business</h2>
              <p>Transforming businesses through innovative digital solutions.</p>
              <div className="expertise-and-why">
                <div className="expertise-section" data-aos="fade-up">
                  <h3>Our Expertise</h3>
                  <div className="list-style">
                    <ul>
                      <li>Advanced Analytics</li>
                      <li>Cloud Solutions</li>
                      <li>Cybersecurity</li>
                      <li>Digital Strategy</li>
                      <li>Enterprise Architecture</li>
                      <li>Mobile App Development</li>
                    </ul>
                  </div>
                </div>
                <div className="why-section" data-aos="fade-up">
                  <h3>Why Choose Us?</h3>
                  <div className="list-style">
                    <ul>
                      <li>Proven Track Record</li>
                      <li>Innovative Solutions</li>
                      <li>Customer-Centric Approach</li>
                      <li>Experienced Team</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
