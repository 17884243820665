import React, { useState, useEffect } from 'react';
import './Loader.css';
import ProgressBar from 'progressbar.js';

export const Loader = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const container = document.getElementById('container');
    const bar = new ProgressBar.Circle(container, {
      color: 'rgb(147 51 234)', // Soft gold color for the progress bar
      strokeWidth: 4,
      trailWidth: 1,
      easing: 'easeInOut',
      duration: 1400,
      text: {
        autoStyleContainer: false
      },
      from: { color: 'rgb(147 51 234)', width: 1 },
      to: { color: 'rgb(147 51 234)', width: 4 },
      step: function(state, circle) {
        circle.path.setAttribute('stroke', state.color);
        circle.path.setAttribute('stroke-width', state.width);

        const value = Math.round(circle.value() * 100);
        if (value === 0) {
          circle.setText('');
        } else {
          circle.setText(value);
        }
      }
    });

    bar.text.style.fontFamily = '"Raleway", Helvetica, sans-serif';
    bar.text.style.fontSize = '2rem';
    bar.animate(1.0);

    // Update the count state
    setCount(100);

  }, []);

  return (
    <div className="loader">
      <div id="container"></div>
      <div className="loader-count">{count}%</div>
    </div>
  );
};
