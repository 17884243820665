import React, { useEffect, useState, useRef } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import './features.css';

export const Features = ({ data }) => {
  const [cards, setCards] = useState([]);
  const carouselRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(Array(data?.length).fill(false));

  useEffect(() => {
    if (data) {
      setCards([...data, ...data, ...data]); 
    }

    // Initialize AOS
    AOS.init({
      duration: 1000,
      once: true,
    });

    // Preload all images when the component mounts
    const preloadImages = () => {
      data.forEach((item) => {
        const img = new Image();
        img.src = item.image;
      });
    };
    preloadImages();

    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % data.length);
      const scrollPosition = (carouselRef.current.scrollWidth / (data.length * 3)) * ((activeIndex + 1) % (data.length * 3));
      carouselRef.current.scrollTo({ left: scrollPosition, behavior: 'smooth' });
    }, 3000);

    return () => clearInterval(interval);
  }, [data, activeIndex]);

  const handleDotClick = (index) => {
    setActiveIndex(index);
    const scrollPosition = (carouselRef.current.scrollWidth / (data.length * 3)) * index;
    carouselRef.current.scrollTo({ left: scrollPosition, behavior: 'smooth' });
  };

  const handleImageLoad = (index) => {
    const updatedLoadState = [...imageLoaded];
    updatedLoadState[index] = true;
    setImageLoaded(updatedLoadState);
  };

  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="section-title">
          <p className="features-title-black">We Guarantee Your Business Success</p>
        </div>
        <div className="carousel-container">
          <div className="carousel" ref={carouselRef}>
            {cards.map((d, i) => (
              <div
                key={`${d.title}-${i}`}
                className="feature-card"
                data-aos="fade-up" // AOS attribute
              >
                <div className="feature-content">
                  {!imageLoaded[i % data.length] && (
                    <div className="feature-loader">
                      <div className="bubble"></div>
                      <div className="bubble"></div>
                      <div className="bubble"></div>
                    </div> 
                  )}
                  <img 
                    src={d.image} 
                    alt={d.title} 
                    className={`feature-image ${imageLoaded[i % data.length] ? "visible" : "hidden"}`}
                    onLoad={() => handleImageLoad(i % data.length)} 
                  />
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="carousel-dots">
          {data && data.map((_, index) => (
            <span
              key={index}
              className={`dot ${activeIndex === index ? 'active' : ''}`}
              onClick={() => handleDotClick(index)}
            ></span>
          ))}
        </div>
      </div>
    </div>
  );
};
