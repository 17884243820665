import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Team.css'; // Import the Team CSS
import { FaTwitter, FaLinkedin, FaFacebook } from 'react-icons/fa'; // Import social icons

const Team = ({ data }) => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <div className="team">
      <div className="container">
      <div className="section-title team-center" data-aos="fade-up">
  <h2>Meet the Team</h2>
  <p>
    At Terry Technologies Limited, we pride ourselves on having a dedicated team of professionals committed to delivering the best ICT solutions. Our diverse expertise ensures that we meet the needs of our clients effectively and efficiently.
  </p>
</div>

        
        <div className="row">
          {data ? (
            data.map((member, index) => (
              <div key={`${member.name}-${index}`} className="col-md-3 col-sm-6">
                <div className="our-team" data-aos="fade-up" data-aos-delay={index * 100}>
                  <div className="pic">
                    <img src={member.img} alt={`${member.name}'s profile`} />
                    <ul className="social">
                      {member.twitter && (
                        <li>
                          <a
                            href={member.twitter}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Twitter"
                          >
                            <FaTwitter />
                          </a>
                        </li>
                      )}
                      {member.linkedin && (
                        <li>
                          <a
                            href={member.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="LinkedIn"
                          >
                            <FaLinkedin />
                          </a>
                        </li>
                      )}
                      {member.facebook && (
                        <li>
                          <a
                            href={member.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Facebook"
                          >
                            <FaFacebook />
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="team-content">
                    <div className="team-info">
                      <h3 className="title">{member.name}</h3>
                      <span className="post">{member.position}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="loading">Loading team members...</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Team;
