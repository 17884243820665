import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './SpecializedTech.css';

export const SpecializedTech = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  const techFields = [
    {
      imgSrc: "img/fintech.jpg",
      title: "FinTech",
      description: "Financial technology innovations for a new age of banking.",
      duration: "6 months",
      students: "500+ students",
      difficulty: "Intermediate",
      rating: "4.5 stars",
      color: "fintech",
      aosEffect: "fade-up"
    },
    {
      imgSrc: "img/edtech.jpg",
      title: "EdTech",
      description: "Transforming education with digital tools.",
      
      color: "edtech",
      aosEffect: "zoom-in"
    },
    {
      imgSrc: "img/medtech.jpg",
      title: "MedTech",
      description: "Advancing healthcare through innovative technology.",
      
      color: "medtech",
      aosEffect: "flip-left"
    },
    {
      imgSrc: "img/agritech.jpg",
      title: "AgriTech",
      description: "Revolutionizing agriculture with technology.",
      
      color: "agritech",
      aosEffect: "fade-right"
    },
    {
      imgSrc: "img/insuretech.jpg",
      title: "InsureTech",
      description: "Innovative solutions for the insurance industry.",
      
      
      color: "insuretech",
      aosEffect: "fade-left"
    },
    {
      imgSrc: "img/energytech.jpg",
      title: "EnergyTech",
      description: "Sustainable energy solutions for a greener future.",
      
      
      color: "energytech",
      aosEffect: "zoom-out"
    },
    {
      imgSrc: "img/esg.jpg",
      title: "ESG",
      description: "Environmental, Social, and Governance practices.",
      
      
      color: "esg",
      aosEffect: "flip-up"
    },
    {
      imgSrc: "img/projectmgmt.jpg",
      title: "Project Management",
      description: "Effective management of projects and teams.",
      
      

      color: "project-management",
      aosEffect: "slide-up"
    }
  ];

  const TechCard = ({ imgSrc, title, description, duration, students, difficulty, rating, color, aosEffect }) => (
    <div className={`card ${color}`} data-aos={aosEffect}>
      <img src={imgSrc} alt={title} className="card-img" />
      <div className="card-content">
        <h3 className="card-title">{title}</h3>
        <p className="card-description">{description}</p>
        <div className="card-info">
          
        </div>
      </div>
    </div>
  );

  return (
    <div className="specialized-tech-container gradient-bg">
      <h2 className="text-3xl font-bold text-center mb-12 animate-fadeIn">
        Our Specialized Tech Fields
      </h2>
      <div className="grid-container">
        {techFields.map((field, index) => (
          <TechCard
            key={index}
            imgSrc={field.imgSrc}
            title={field.title}
            description={field.description}
            duration={field.duration}
            students={field.students}
            difficulty={field.difficulty}
            rating={field.rating}
            color={field.color}
            aosEffect={field.aosEffect}
          />
        ))}
      </div>
    </div>
  );
};

export default SpecializedTech;
